import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AddIcon from '@material-ui/icons/Add';
import Dialog from 'components/Common/Dialog';
import ResourcesTable from '../ResourcesTable';
import Link from 'components/Common/Link';
import { getResources, getResourceMetadata } from 'store/modules/resources';
import { usePrevious } from 'utils/hooks';
import Tooltip from '@material-ui/core/Tooltip';

export const ResourcesSelectionPropTypes = {};

const getName = (filename) => {
  const names = filename.split('/');
  if (names[names.length - 1] === '') names.pop();
  return names[names.length - 1];
};

const getInputPath = (metadata, inputPath, confirmedResource) => {
  const safeInputPath = inputPath.replace(/\+/g, ' ');
  const confirmedResourcePath = confirmedResource ? `${confirmedResource.folderPath}${confirmedResource.filename}` : '';
  const metadataPath = metadata[safeInputPath]
    ? `${metadata[safeInputPath].folderPath}/${metadata[safeInputPath].filename}`
    : '';
  const resourceInputPath = confirmedResourcePath || metadataPath;
  return resourceInputPath;
};

function ResourcesSelection({ inputPath = '', onChange, className, validate = false }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [folder, setFolder] = useState();
  const [folders, setFolders] = useState([]);
  const [selectedResource, setSelectedResource] = useState({});
  const [confirmedResource, setConfirmedResource] = useState('');
  const resources = useSelector((state) => state.resources.items);
  const loading = useSelector((state) => state.resources.loading);
  const metadata = useSelector((state) => state.resources.metadata);
  const prevOpen = usePrevious(open);

  const handleOpenDialog = () => setOpen(true);

  const handleCloseDialog = () => {
    setFolder();
    setSelectedResource({});
    setOpen(false);
  };

  const handleConfirm = () => {
    setConfirmedResource(selectedResource);
    onChange(selectedResource.s3Path || '');
    handleCloseDialog();
  };

  const handleRowClick = (resource) => () => {
    const { resourceType, s3Path } = resource;
    if (resourceType === 'Folder') {
      setFolder(s3Path);
      setSelectedResource({});
    } else {
      if (selectedResource.id === resource.id) setSelectedResource({});
      else setSelectedResource(resource);
    }
  };

  const handleClick = (folder) => () => {
    setFolder(folder);
  };

  useEffect(() => {
    if (open) {
      dispatch(getResources({ folder }));
    }
  }, [folder, open]);

  useEffect(() => {
    if (!inputPath || metadata[inputPath]) {
      return;
    }
    dispatch(getResourceMetadata({ inputPath }));
  }, [inputPath]);

  useEffect(() => {
    if (!folder) setFolders([]);
    else {
      const paths = folder.split('/');
      if (paths[paths.length - 1] === '') paths.pop();
      const folders = [];
      paths.forEach((o, index) => {
        if (index === 0) folders.push(`${o}/`);
        else folders.push(`${folders[folders.length - 1]}${o}/`);
      });
      setFolders(folders);
    }
  }, [folder]);

  useEffect(() => {
    if (open && !prevOpen && confirmedResource) {
      setSelectedResource(confirmedResource);
      setFolder(confirmedResource.folderPath);
    }
  }, [open, prevOpen, confirmedResource]);

  const resourceInputPath = getInputPath(metadata, inputPath, confirmedResource);
  const filename = resourceInputPath && resourceInputPath.split('/').slice(-1);
  const isValid = !!filename;
  return (
    <div className={className}>
      <Tooltip title={filename || ''} arrow>
        <Input
          error={validate && !isValid}
          id="resource"
          type="text"
          style={{ flex: 1 }}
          value={filename}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="open select resource dialog" onClick={handleOpenDialog}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Tooltip>
      <Dialog
        open={!!open}
        title="Select a Resource"
        onConfirm={handleConfirm}
        onClose={handleCloseDialog}
        onCancel={handleCloseDialog}
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link onClick={handleClick()} href="">
              root
            </Link>
            {folders.map((o, index) => {
              if (index === folders.length - 1) return <Typography key={o}>{getName(o)}</Typography>;
              return (
                <Link key={o} href="" onClick={handleClick(o)}>
                  {getName(o)}
                </Link>
              );
            })}
          </Breadcrumbs>
          <ResourcesTable
            resources={resources}
            loading={loading}
            onClick={handleRowClick}
            handleChecked={handleRowClick}
            selectedResources={selectedResource ? [selectedResource.id] : []}
          />
        </div>
      </Dialog>
    </div>
  );
}
ResourcesSelection.propTypes = ResourcesSelectionPropTypes;

ResourcesSelection.defaultProps = {};

export default ResourcesSelection;
