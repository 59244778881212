import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nodeTypeScroll, Paper } from '../../Styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Actions from '../../Common/Actions';
import TooltipTitle from 'components/Common/TooltipTitle';
import ResourceSelection from 'components/Database/ResourcesSelection';
import { NODE_TYPES } from 'utils/constants';

export const CreateSeismicPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  input_path: '',
  datum: 0,
  segYStandard: 'standard',
  iline: 0,
  xline: 0,
  cdp_x: 0,
  cdp_y: 0,
  nodeType: NODE_TYPES.CREATE_SEISMIC
};

function CreateSeismic({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeScroll();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleResourceChange = (prop) => (value) => {
    setValues({ ...values, [prop]: value });
  };

  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;
  const emptyReferenceName = saveNode && !values.reference_name ? 'Reference name is required' : null;

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="CreateSeismic" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Seismic Selection</TooltipTitle>
        <FormControl fullWidth>
          <ResourceSelection inputPath={values.input_path} onChange={handleResourceChange('input_path')} />
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle>Seismic Reference Datum</TooltipTitle>
        <TextField
          margin="dense"
          id="seismic-reference-datum"
          type="number"
          fullWidth
          onChange={handleChange('datum')}
          value={values.datum}
        />
      </Paper>
      <Paper>
        <TooltipTitle>SEG-Y Standard</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="seg-y-standard-select-label"
            id="seg-y-standard-select"
            onChange={handleChange('segYStandard')}
            value={values.segYStandard}
          >
            <MenuItem value="standard">SEG-Y Rev.1</MenuItem>
            <MenuItem value="non-standard">Non-Standard</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle>Seismic reference name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Reference Name"
          fullWidth
          onChange={handleChange('reference_name')}
          value={values.reference_name}
          error={!!emptyReferenceName}
          helperText={emptyReferenceName}
        />
      </Paper>
      {values.segYStandard === 'non-standard' && (
        <>
          <Paper>
            <TooltipTitle>Inline Byte Location</TooltipTitle>
            <TextField
              margin="dense"
              id="iline"
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
              onChange={handleChange('iline')}
              value={values.iline}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Crossline Byte Location</TooltipTitle>
            <TextField
              margin="dense"
              id="xline"
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
              onChange={handleChange('xline')}
              value={values.xline}
            />
          </Paper>
          <Paper>
            <TooltipTitle>X-Coordinate Byte Location</TooltipTitle>
            <TextField
              margin="dense"
              id="cdp_x"
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
              onChange={handleChange('cdp_x')}
              value={values.cdp_x}
            />
          </Paper>
          <Paper>
            <TooltipTitle>Y-Coordinate Byte Location</TooltipTitle>
            <TextField
              margin="dense"
              id="cdp_y"
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
              onChange={handleChange('cdp_y')}
              value={values.cdp_y}
            />
          </Paper>
        </>
      )}
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}
CreateSeismic.propTypes = CreateSeismicPropTypes;

CreateSeismic.defaultProps = {};

export default CreateSeismic;
