import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nodeTypeScroll, Paper } from '../../Styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Actions from '../../Common/Actions';
import TooltipTitle from 'components/Common/TooltipTitle';
import ResourceSelection from 'components/Database/ResourcesSelection';
import { NODE_TYPES } from 'utils/constants';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import NodeName from 'components/Nodes/Common/Components/NodeName';
import { makeStyles } from '@material-ui/core/styles';
import SeismicTable from './SeismicFilesTable';

export const BulkCreateSeismicPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
const seismicFileClasses = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  }
}));
const initialValues = {
  seismic_files: [{ seismic_name: '', file: '' }],
  name: '',
  datum: 0,
  segYStandard: 'standard',
  iline: 0,
  xline: 0,
  cdp_x: 0,
  cdp_y: 0,
  nodeType: NODE_TYPES.BUlK_CREATE_SEISMIC
};

function createSeismicFile() {
  return { seismic_name: '', file: '' };
}
export const BulkCreateSeismic = ({ data, isCreated = false, onCancel, onSave, onShowLogs }) => {
  const classes = nodeTypeScroll();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleAdd = (event) => {
    setValues((prevstate) => ({ ...prevstate, seismic_files: [...prevstate.seismic_files, createSeismicFile()] }));
  };

  const handleFilePathChange = (index) => (value) => {
    const newSeismicFiles = values.seismic_files.map((v, i) => {
      if (i === index) return { ...v, file: value };
      return v;
    });
    setValues((prevstate) => ({ ...prevstate, seismic_files: newSeismicFiles }));
  };
  const handleFileNameChange = (index) => (event) => {
    const newSeismicFiles = values.seismic_files.map((v, i) => {
      if (i === index) return { ...v, seismic_name: event.target.value || '' };
      return v;
    });
    setValues((prevstate) => ({ ...prevstate, seismic_files: newSeismicFiles }));
  };

  const handleFileRemove = (index) => (event) => {
    setValues((prevstate) => ({ ...prevstate, seismic_files: values.seismic_files.filter((v, i) => i !== index) }));
  };

  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;
  const validSeismicFiles = values.seismic_files.every((x) => !!x.file && !!x.seismic_name);

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '' && validSeismicFiles) {
      onSave(values);
      setSaveNode(false);
    }
  };
  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <>
      <div data-testid="BulkCreateSeismic" className={classes.root}>
        BULK
        <NodeName onChange={handleChange('name')} value={values.name} emptyNodeName={emptyNodeName}></NodeName>
        <Paper>
          <TooltipTitle>Seismic Selection</TooltipTitle>

          <SeismicTable
            seismic_files={values.seismic_files}
            handleFileNameChange={handleFileNameChange}
            handleDelete={handleFileRemove}
            handleFilePathChange={handleFilePathChange}
            validate={saveNode}
          />
          <div className={seismicFileClasses().actions}>
            <IconButton aria-label="add" onClick={handleAdd}>
              <AddIcon />
            </IconButton>
          </div>
        </Paper>
        <Paper>
          <TooltipTitle>Seismic Reference Datum</TooltipTitle>
          <TextField
            margin="dense"
            id="seismic-reference-datum"
            type="number"
            fullWidth
            onChange={handleChange('datum')}
            value={values.datum}
          />
        </Paper>
        <Paper>
          <TooltipTitle>SEG-Y Standard</TooltipTitle>
          <FormControl fullWidth>
            <Select
              labelId="seg-y-standard-select-label"
              id="seg-y-standard-select"
              onChange={handleChange('segYStandard')}
              value={values.segYStandard}
            >
              <MenuItem value="standard">SEG-Y Rev.1</MenuItem>
              <MenuItem value="non-standard">Non-Standard</MenuItem>
            </Select>
          </FormControl>
        </Paper>
        {values.segYStandard === 'non-standard' && (
          <>
            <Paper>
              <TooltipTitle>Inline Byte Location</TooltipTitle>
              <TextField
                margin="dense"
                id="iline"
                type="number"
                fullWidth
                InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
                onChange={handleChange('iline')}
                value={values.iline}
              />
            </Paper>
            <Paper>
              <TooltipTitle>Crossline Byte Location</TooltipTitle>
              <TextField
                margin="dense"
                id="xline"
                type="number"
                fullWidth
                InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
                onChange={handleChange('xline')}
                value={values.xline}
              />
            </Paper>
            <Paper>
              <TooltipTitle>X-Coordinate Byte Location</TooltipTitle>
              <TextField
                margin="dense"
                id="cdp_x"
                type="number"
                fullWidth
                InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
                onChange={handleChange('cdp_x')}
                value={values.cdp_x}
              />
            </Paper>
            <Paper>
              <TooltipTitle>Y-Coordinate Byte Location</TooltipTitle>
              <TextField
                margin="dense"
                id="cdp_y"
                type="number"
                fullWidth
                InputProps={{ inputProps: { min: 1, max: 204, step: 1 } }}
                onChange={handleChange('cdp_y')}
                value={values.cdp_y}
              />
            </Paper>
          </>
        )}
        <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
      </div>
    </>
  );
};

export default BulkCreateSeismic;
